<template>
  <div class="active-order-error" :class="{ _mobile: isMobile }">
    <svg-icon
      name="52/Error_52"
      class="active-order-error__icon"
      :width="52"
      :height="52"
    />
    <h4 v-if="errorMessage" class="active-order-error__title">
      {{ errorMessage }}
    </h4>
    <p v-if="errorMessageLong" class="active-order-error__info">
      {{ errorMessageLong }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ActiveOrderError',
  props: {
    errorMessage: { type: String, default: 'К сожалению, заказ уже невозможно изменить' },
    errorMessageLong: { type: String, default: '' },
    isMobile: { type: Boolean, default: false },
  },
});
</script>

<style lang="less">
.active-order-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__icon {
    fill: @pink-error;
    margin-bottom: @size-x4;
  }

  &__title {
    .h4-desktop();
  }

  &__info {
    .p();

    color: @black;
  }

  &._mobile {
    .container-m();
  }
}
</style>
